
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IPhoneProp from "../components/IPhoneProp";
import TextWave from "../components/TextWave";

import "./home.css"
const Home = () => {
  return (
    <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <IPhoneProp/>
            <div>
            <span className="font-span">
            Hotter than the average sasquatch?
            
            </span>
            </div>
            <div>
            <span className="font-span">
            Try our app and find out!

            </span>
            </div>

            <div className="features">

            <div className="features-container">
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Discover Your Attractiveness with Snyggster!</h3>
                  <p>Welcome to Snyggster, the ultimate platform designed to help you discover just how attractive you are! Our app offers a unique and engaging experience that lets you see how others perceive your attractiveness, all while connecting with friends and exploring the world around you.
                  </p>
              </div>
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Quick and Easy Setup</h3>
                  <p>Getting started with Snyggster is a breeze. Simply download the app and complete our quick 30-second setup by selecting your age and gender, as well as the age group and gender (men or women) you want to vote for. Enjoy a seamless experience without the hassle of passwords, ensuring both security and comfort. Dive into the Snyggster experience in no time!
                  </p>
              </div>

              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Capture the Real You</h3>
                  <p>Snyggster features a built-in camera function designed to ensure authenticity. This means no cheating, no filters, no AI-generated images, and no photoshopped enhancements. Simply snap a genuine photo of yourself and let the Snyggster community provide their feedback.
                  </p>
              </div>
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Engage and Explore</h3>
                  <p>Once your photo is submitted, it can receive up to 100 votes from users worldwide. While waiting for your results, you can join the fun by casting votes for others, rating them on a scale from 1 to 10. Remember, you can only vote once per user, making each vote impactful and meaningful.
                  </p>
              </div>
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Challenge and Conquer</h3>
                  <p>Take the excitement further by challenging your friends! Discover comprehensive statistics that show how you rank in attractiveness compared to others around you, across your country, or even globally. Snyggster gives you the insights you need to find out who truly stands out.</p>
              </div>
              <div className="feature-box">
                  <div className="icon"></div>
                  <h3>Fast Results, Big Revelations</h3>
                  <p>Our app is designed to get you answers swiftly, with minimal wait time between setting up and receiving your results. Dive into a world of beauty assessment that is as fast as it is fascinating.
                  </p>
                  </div>

                </div>

               </div>

        </div>
    </>
 
  );
};

export default Home;


/*
**Discover Your Attractiveness with Snyggster!**

Welcome to Snyggster, the ultimate platform designed to help you discover just how attractive you are! Our app offers a unique and engaging experience that lets you see how others perceive your attractiveness, all while connecting with friends and exploring the world around you.

**Quick and Easy Setup:**  
Getting started with Snyggster is a breeze. Download the app, complete our simple setup by selecting your age, gender, and the age group you want to vote on, as well as who you want to cast votes for – men or women. You’ll be ready to dive into the Snyggster experience in no time.

**Capture the Real You:**  
Snyggster features a built-in camera function designed to ensure authenticity. This means no cheating, no filters, no AI-generated images, and no photoshopped enhancements. Simply snap a genuine photo of yourself and let the Snyggster community provide their feedback.

**Engage and Explore:**  
Once your photo is submitted, it can receive up to 100 votes from users worldwide. While waiting for your results, you can join the fun by casting votes for others, rating them on a scale from 1 to 10. Remember, you can only vote once per user, making each vote impactful and meaningful.

**Challenge and Conquer:**  
Take the excitement further by challenging your friends! Discover comprehensive statistics that show how you rank in attractiveness compared to others around you, across your country, or even globally. Snyggster gives you the insights you need to find out who truly stands out.

**Fast Results, Big Revelations:**  
Our app is designed to get you answers swiftly, with minimal wait time between setting up and receiving your results. Dive into a world of beauty assessment that is as fast as it is fascinating.

Join Snyggster today and start uncovering your attractiveness with a touch of excitement and insight. Are you ready to see where you stand? Download now and find out!
*/
