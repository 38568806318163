
import React, { useState, useEffect } from 'react';
import './IPhoneProp.css';
import { motion } from "framer-motion";

const IPhone16Prop = () => {
  const [position, setPosition] = useState({ left:0, top: 0 });
  const [scale, setScale] = useState(0.6);

  const imageSize = 512;

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Randomly change position for demonstration purposes
      const randomX = (Math.floor(Math.random() * 250)-500)-200; // Modify as needed
      const randomY = (Math.floor(Math.random() * 100)-200); // Modify as needed
      setPosition({ x: randomX, y: randomY });
    }, Math.random()*2000); // Update every 2 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);


  return (
    <div className="image-container">
      <img
        src="IPhone16.png"
        alt="base"
        className="base-image"
      />
      <div className="inner-div">
      {/*
      <motion.img
          src="confused.jpg"
          alt="overlay"
          className="overlay-image"
          animate={{ x: position.x, y: position.y }}
          transition={{ type: "spring", stiffness: 200, damping: 20 }} // Adjust transition as needed

        />
        */}

        <img
          src="confused.jpg"
          alt="overlay"
          className="overlay-image"
        />
        </div>
      
    </div>
  );
};

export default IPhone16Prop;

