
import { React, useState, useRef, useEffect } from "react";
import "./contact.css"; 
import baseUrl from "../urlenv";
import ReCAPTCHA from 'react-google-recaptcha';
import PulseLoader from "react-spinners/PulseLoader";

const Contact = () => {
  const captchaSiteKey = process.env.REACT_APP_API_CAPTCHA_PUBLIC;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [topic, setTopic] = useState('');
  
  const [status, setStatus] = useState({ message: '', success: null, loading: false });
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const recaptcha = useRef();
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const topicRef = useRef(null);
  const messageRef = useRef(null);

  useEffect(() => {
    setShowRecaptcha(email.length >0 && name.length >0 && message.length >0 && topic.length >0);

  },[email,name,message,topic]);


  async function submitForm(event) {
    event.preventDefault();


    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const topic = topicRef.current.value;
    const message = messageRef.current.value;

    const params = new URLSearchParams();
    params.append('name', name);
    params.append('email', email);
    params.append('topic', topic);
    params.append('message', message);

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert('Please verify the reCAPTCHA!');
    } else {
      setStatus({ success: null, loading: true });

        fetch(`${baseUrl.apiUrl}/recaptcha`, {
          method: 'POST',
          body: JSON.stringify({ captchaValue }),
          headers: {
            'content-type': 'application/json',
          },
        }).then((response) => response.json())
        .then((data) => {

          if (data.success) {
            fetch(`${baseUrl.apiUrl}/contactMessage`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: params.toString(),
            })
            .then((response) => response.json())
            .then((data) => {
              setStatus({
                success: data.success,
                loading: false,
                message: data.success
                  ? 'Your message has been succesfully sent!'
                  : 'wopsie, there was an issue sending your message',
              });
              recaptcha.current.reset();
              setShowRecaptcha(false);
            })
            .catch((error) => {
              setStatus({
                success: false,
                loading: false,
                message: 'Wopsie! There was an error while trying to access our server',
              });
              console.log('Fetch Error:', error);
              setShowRecaptcha(false);
            });
          } else {
            setStatus({
              success: false,
              loading: false,
              message: 'ReCAPTCHA Verification failed.',
            });
            setShowRecaptcha(false);
          }

        }).catch((error) => {

          setStatus({
            success: false,
            loading: false,
            message: 'Wopsie! There was an error while trying to access our server',
          });
          console.log('Fetch Error:', error);
          setShowRecaptcha(false);
        });
      }
    }

  return (
    <div className="beta-container">
      <div>
      
        <h3>Want to contact us?</h3>

        {!status.loading && (
          <h2 className={status.success ? 'message-success' : 'message-error'}>
            {status.message}
          </h2>
        )}

          {status.loading  &&(<PulseLoader

            color="#252525"
            loading={true}
            cssOverride={true}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)}


          <form onSubmit={submitForm}>
            
            {!status.success && <div className="form-container">

                <select
                    name="Topic"
                    value={topic}
                    ref={topicRef}
                    onChange={(event) => setTopic(event.target.value)}
                    required
                    className="input-field"
                    style={{width:420}}
                >
                    <option value="" disabled>Select a topic</option>
                    <option value="general">General Inquiry</option>
                    <option value="support">Support</option>
                    <option value="feedback">Feedback</option>  
                    <option value="press">Press & Media</option>
                    <option value="partnerships">Partnerships & Collaboration</option>
                    <option value="investors">Investor Inquiries</option>
                    <option value="careers">Careers</option>
                    <option value="technical">Technical Issues</option>
                    <option value="other">Other</option>
                </select>

                <input
                    name="Email"
                    type="email"
                    value={email}
                    ref={emailRef}
                    required
                    placeholder="email@example.com"
                    onChange={(event) => setEmail(event.target.value)}
                    className="input-field"
                    style={{width:400}}
                />
                <input
                    name="Name"
                    type="text"
                    value={name}
                    ref={nameRef}
                    required
                    placeholder="my name"
                    onChange={(event) => setName(event.target.value)}
                    className="input-field"
                    style={{width:400}}
                />


                <textarea
                    name="Message"
                    value={message}
                    ref={messageRef}
                    onChange={(event) => setMessage(event.target.value)}
                    required
                    placeholder="Write your message here..."
                    className="input-field"
                    style={{width:400, height:100}}
                />

                <button type="submit" className= "submit-button" disabled={status.loading} >
                    Submit!
                </button>


                </div>}


            {showRecaptcha && (
              <div className="recaptcha-container">
                <ReCAPTCHA ref={recaptcha} sitekey={captchaSiteKey} />
              </div>
            )}
          </form>
      
      </div>
    </div>
  );
};

export default Contact;
